import { Injectable } from '@angular/core';
import { collection } from './collection.model';
import { Dress } from './dress.model';

@Injectable({
  providedIn: 'root',
})
export class collectionService {
  constructor() { }

  singlecollection: collection;
  singleDress: Dress;
  collectionName: string;
  dressIndex: number;

  collections = [


    new collection(
      'collection Bloomed Bliss 2024',
      [
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs1.1.webp', '/assets/images7/drs1.2.webp', '/assets/images7/drs1.3.webp', '/assets/images7/drs1.4.webp', '/assets/images7/drs1.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          801,
          '/assets/images7/drs1.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs2.1.webp', '/assets/images7/drs2.2.webp', '/assets/images7/drs2.3.webp', '/assets/images7/drs2.4.webp', '/assets/images7/drs2.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          802,
          '/assets/images7/drs2.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs3.1.webp', '/assets/images7/drs3.2.webp', '/assets/images7/drs3.3.webp', '/assets/images7/drs3.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          803,
          '/assets/images7/drs3.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs4.1.webp', '/assets/images7/drs4.2.webp', '/assets/images7/drs4.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          804,
          '/assets/images7/drs4.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs5.1.webp', '/assets/images7/drs5.2.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          805,
          '/assets/images7/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs6.1.webp', '/assets/images7/drs6.2.webp', '/assets/images7/drs6.3.webp', '/assets/images7/drs6.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          806,
          '/assets/images7/drs6.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs7.1.webp', '/assets/images7/drs7.2.webp', '/assets/images7/drs7.3.webp','/assets/images7/drs7.4.webp','/assets/images7/drs7.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          807,
          '/assets/images7/drs7.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs8.1.webp', '/assets/images7/drs8.2.webp', '/assets/images7/drs8.3.webp', '/assets/images7/drs8.4.webp', '/assets/images7/drs8.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          808,
          '/assets/images7/drs8.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs9.1.webp', '/assets/images7/drs9.2.webp', '/assets/images7/drs9.3.webp', '/assets/images7/drs9.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          809,
          '/assets/images9/drs9.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs10.1.webp', '/assets/images7/drs10.2.webp', '/assets/images7/drs10.3.webp', '/assets/images7/drs10.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          810,
          '/assets/images7/drs10.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs11.1.webp', '/assets/images7/drs11.2.webp', '/assets/images7/drs11.3.webp', '/assets/images7/drs11.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          330,
          '34 - 54',
          811,
          '/assets/images7/drs11.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs12.1.webp', '/assets/images7/drs12.2.webp', '/assets/images7/drs12.3.webp', '/assets/images7/drs12.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          812,
          '/assets/images7/drs12.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs13.1.webp', '/assets/images7/drs13.2.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          813,
          '/assets/images7/drs13.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs14.1.webp', '/assets/images7/drs14.2.webp', '/assets/images7/drs14.3.webp', '/assets/images7/drs14.4.webp', '/assets/images7/drs14.5.webp', '/assets/images7/drs14.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          814,
          '/assets/images7/drs14.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs15.1.webp', '/assets/images7/drs15.2.webp', '/assets/images7/drs15.3.webp', '/assets/images7/drs15.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          815,
          '/assets/images7/drs15.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs16.1.webp', '/assets/images7/drs16.2.webp', '/assets/images7/drs16.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          816,
          '/assets/images7/drs16.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs17.1.webp', '/assets/images7/drs17.2.webp', '/assets/images7/drs17.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          817,
          '/assets/images7/drs17.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs18.1.webp', '/assets/images7/drs18.2.webp', '/assets/images7/drs18.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          118,
          '/assets/images7/drs18.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs19.1.webp', '/assets/images7/drs19.2.webp', '/assets/images7/drs19.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          119,
          '/assets/images7/drs19.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images7/drs20.1.webp', '/assets/images7/drs20.2.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          820,
          '/assets/images7/drs20.1.webp'
        ),
        
      
        

      ],
      'See more collection Bloomed Bliss 2024',
      '/assets/collages/collage_8.jpg'
    ),

































    new collection(
      'collection Lace Harmony 2024',
      [
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs1.1.webp', '/assets/images8/drs1.2.webp', '/assets/images8/drs1.3.webp', '/assets/images8/drs1.4.webp', '/assets/images8/drs1.5.webp', '/assets/images8/drs1.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          901,
          '/assets/images8/drs1.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs2.1.webp', '/assets/images8/drs2.2.webp', '/assets/images8/drs2.3.webp', '/assets/images8/drs2.4.webp', '/assets/images8/drs2.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          902,
          '/assets/images8/drs2.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs3.1.webp', '/assets/images8/drs3.2.webp', '/assets/images8/drs3.3.webp', '/assets/images8/drs3.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          903,
          '/assets/images8/drs3.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs4.1.webp', '/assets/images8/drs4.2.webp', '/assets/images8/drs4.3.webp', '/assets/images8/drs4.3.webp', '/assets/images8/drs4.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          904,
          '/assets/images8/drs4.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs5.1.webp', '/assets/images8/drs5.2.webp', '/assets/images8/drs5.2.webp', '/assets/images8/drs5.2.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          905,
          '/assets/image8/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs6.1.webp', '/assets/images8/drs6.2.webp', '/assets/images8/drs6.3.webp', '/assets/images8/drs6.4.webp', '/assets/images8/drs6.5.webp', '/assets/images8/drs6.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          906,
          '/assets/images8/drs6.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs7.1.webp', '/assets/images8/drs7.2.webp', '/assets/images8/drs7.3.webp','/assets/images8/drs7.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          907,
          '/assets/images8/drs7.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs8.1.webp', '/assets/images8/drs8.2.webp', '/assets/images8/drs8.3.webp', '/assets/images8/drs8.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          908,
          '/assets/images8/drs8.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs9.1.webp', '/assets/images8/drs9.2.webp', '/assets/images8/drs9.3.webp', '/assets/images8/drs9.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          909,
          '/assets/images8/drs9.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs10.1.webp', '/assets/images8/drs10.2.webp', '/assets/images8/drs10.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          910,
          '/assets/images8/drs10.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs11.1.webp', '/assets/images8/drs11.2.webp', '/assets/images8/drs11.3.webp', '/assets/images8/drs11.4.webp', '/assets/images8/drs11.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          330,
          '34 - 54',
          911,
          '/assets/images8/drs11.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs12.1.webp', '/assets/images8/drs12.2.webp', '/assets/images8/drs12.3.webp', '/assets/images8/drs12.4.webp', '/assets/images8/drs12.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          912,
          '/assets/images8/drs12.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs13.1.webp', '/assets/images8/drs13.2.webp', '/assets/images8/drs13.3.webp', '/assets/images8/drs13.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          913,
          '/assets/images8/drs13.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs14.1.webp', '/assets/images8/drs14.2.webp', '/assets/images8/drs14.3.webp', '/assets/images8/drs14.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          914,
          '/assets/images8/drs14.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs15.1.webp', '/assets/images8/drs15.2.webp', '/assets/images8/drs15.3.webp', '/assets/images8/drs15.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          915,
          '/assets/images8/drs15.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs16.1.webp', '/assets/images8/drs16.2.webp', '/assets/images8/drs16.3.webp', '/assets/images8/drs16.4.webp', '/assets/images8/drs16.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          916,
          '/assets/images8/drs16.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs17.1.webp', '/assets/images8/drs17.2.webp', '/assets/images8/drs17.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          917,
          '/assets/images8/drs17.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs18.1.webp', '/assets/images8/drs18.2.webp', '/assets/images8/drs18.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          918,
          '/assets/images8/drs18.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs19.1.webp', '/assets/images8/drs19.2.webp', '/assets/images8/drs19.3.webp', '/assets/images8/drs19.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          119,
          '/assets/images8/drs19.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images8/drs20.1.webp', '/assets/images8/drs20.2.webp', '/assets/images8/drs20.3.webp', '/assets/images8/drs20.4.webp', '/assets/images8/drs20.5.webp', '/assets/images8/drs20.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          920,
          '/assets/images8/drs20.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
        'platya32',// POHUI
        ['/assets/images8/drs21.1.webp', '/assets/images8/drs21.2.webp', '/assets/images8/drs21.3.webp', '/assets/images8/drs21.4.webp', '/assets/images8/drs21.5.webp'],
        'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
        320,
        '34 - 54',
        921,
        '/assets/images8/drs21.1.webp'
      ), 
      new Dress( // KOPIRUVATU Z VITSU
      'platya32',// POHUI
      ['/assets/images8/drs22.1.webp', '/assets/images8/drs22.2.webp', '/assets/images8/drs22.3.webp'],
      'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
      320,
      '34 - 54',
      922,
      '/assets/images8/drs22.1.webp'
    ), 
    new Dress( // KOPIRUVATU Z VITSU
    'platya32',// POHUI
    ['/assets/images8/drs23.1.webp', '/assets/images8/drs23.2.webp', '/assets/images8/drs23.3.webp'],
    'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
    320,
    '34 - 54',
    923,
    '/assets/images8/drs23.1.webp'
  ),
   new Dress( // KOPIRUVATU Z VITSU
  'platya32',// POHUI
  ['/assets/images8/drs24.1.webp', '/assets/images8/drs24.2.webp', '/assets/images8/drs24.3.webp', '/assets/images8/drs24.4.webp', '/assets/images8/drs24.5.webp'],
  'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
  320,
  '34 - 54',
  924,
  '/assets/images8/drs24.1.webp'
),
 new Dress( // KOPIRUVATU Z VITSU
'platya32',// POHUI
['/assets/images8/drs25.1.webp', '/assets/images8/drs25.2.webp'],
'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
320,
'34 - 54',
925,
'/assets/images8/drs25.1.webp'
),
      
        

      ],
      'See more collection Lace Harmony 2024',
      '/assets/collages/collage_9.jpg'
    ),




















    new collection(
      'collection Esmee 2023',
      [
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs1.1.webp', '/assets/images6/drs1.2.webp', '/assets/images6/drs1.3.webp', '/assets/images6/drs1.4.webp', '/assets/images6/drs1.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          701,
          '/assets/images6/drs1.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs2.1.webp', '/assets/images6/drs2.2.webp', '/assets/images6/drs2.3.webp', '/assets/images6/drs2.4.webp', '/assets/images6/drs2.5.webp', '/assets/images6/drs2.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          702,
          '/assets/images6/drs2.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs3.1.webp', '/assets/images6/drs3.2.webp', '/assets/images6/drs3.3.webp', '/assets/images6/drs3.4.webp', '/assets/images6/drs3.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          703,
          '/assets/images6/drs3.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs4.1.webp', '/assets/images6/drs4.2.webp', '/assets/images6/drs4.3.webp', '/assets/images6/drs4.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          704,
          '/assets/images6/drs4.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs5.1.webp', '/assets/images6/drs5.2.webp', '/assets/images6/drs5.3.webp', '/assets/images6/drs5.4.webp', '/assets/images6/drs5.5.webp', '/assets/images6/drs5.6.webp', '/assets/images6/drs5.7.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          705,
          '/assets/images6/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs6.1.webp', '/assets/images6/drs6.2.webp', '/assets/images6/drs6.3.webp', '/assets/images6/drs6.4.webp', '/assets/images6/drs6.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          706,
          '/assets/images6/drs6.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs7.1.webp', '/assets/images6/drs7.2.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          707,
          '/assets/images6/drs7.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs8.1.webp', '/assets/images6/drs8.2.webp', '/assets/images6/drs8.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          708,
          '/assets/images6/drs8.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs9.1.webp', '/assets/images6/drs9.2.webp', '/assets/images6/drs9.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          709,
          '/assets/images6/drs9.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs10.1.webp', '/assets/images6/drs10.2.webp', '/assets/images6/drs10.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          710,
          '/assets/images6/drs10.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs11.1.webp', '/assets/images6/drs11.2.webp', '/assets/images6/drs11.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          330,
          '34 - 54',
          711,
          '/assets/images6/drs11.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs12.1.webp', '/assets/images6/drs12.2.webp', '/assets/images6/drs12.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          712,
          '/assets/images6/drs12.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs13.1.webp', '/assets/images6/drs13.2.webp', '/assets/images6/drs13.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          713,
          '/assets/images6/drs13.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs14.1.webp', '/assets/images6/drs14.2.webp', '/assets/images6/drs14.3.webp', '/assets/images6/drs14.4.webp', '/assets/images6/drs14.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          714,
          '/assets/images6/drs14.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images6/drs15.1.webp', '/assets/images6/drs15.2.webp', '/assets/images6/drs15.3.webp', '/assets/images6/drs15.4.webp', '/assets/images6/drs15.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          715,
          '/assets/images6/drs15.1.webp'
        ),
        
        
      
        

      ],
      'See more collection Esmee 2023',
      '/assets/collages/collage_7.jpg'
    ),






















    new collection(
      'collection Allure 2023',
      [
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs1.1.webp', '/assets/images/drs1.2.webp', '/assets/images/drs1.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          389,
          '/assets/images/drs1.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs2.1.webp', '/assets/images/drs2.2.webp', '/assets/images/drs2.3.webp', '/assets/images/drs2.4.webp', '/assets/images/drs2.5.webp', '/assets/images/drs2.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          390,
          '/assets/images/drs2.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs3.1.webp', '/assets/images/drs3.2.webp', '/assets/images/drs3.3.webp', '/assets/images/drs3.4.webp', '/assets/images/drs3.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          391,
          '/assets/images/drs3.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs4.1.webp', '/assets/images/drs4.2.webp', '/assets/images/drs4.3.webp', '/assets/images/drs4.4.webp', '/assets/images/drs4.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          392,
          '/assets/images/drs4.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs5.1.webp', '/assets/images/drs5.2.webp', '/assets/images/drs5.3.webp', '/assets/images/drs5.4.webp', '/assets/images/drs5.5.webp', '/assets/images/drs5.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          393,
          '/assets/images/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs6.1.webp', '/assets/images/drs6.2.webp', '/assets/images/drs6..webp', '/assets/images/drs6.4.webp', '/assets/images/drs6.5.webp', '/assets/images/drs6.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          394,
          '/assets/images/drs6.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs7.1.webp', '/assets/images/drs7.2.webp', '/assets/images/drs7.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          395,
          '/assets/images/drs7.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs8.1.webp', '/assets/images/drs8.2.webp', '/assets/images/drs8.3.webp', '/assets/images/drs8.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          396,
          '/assets/images/drs8.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs9.1.webp', '/assets/images/drs9.2.webp', '/assets/images/drs9.3.webp', '/assets/images/drs9.4.webp', '/assets/images/drs9.5.webp', '/assets/images/drs9.6.webp', '/assets/images/drs9.7.webp', '/assets/images/drs9.8.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          397,
          '/assets/images/drs9.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs10.1.webp', '/assets/images/drs10.2.webp', '/assets/images/drs10.3.webp', '/assets/images/drs10.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          398,
          '/assets/images/drs10.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs11.1.webp', '/assets/images/drs11.2.webp', '/assets/images/drs11.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          330,
          '34 - 54',
          399,
          '/assets/images/drs11.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs12.1.webp', '/assets/images/drs12.2.webp', '/assets/images/drs12.3.webp', '/assets/images/drs12.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          400,
          '/assets/images/drs12.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs13.1.webp', '/assets/images/drs13.2.webp', '/assets/images/drs13.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          401,
          '/assets/images/drs13.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs14.1.webp', '/assets/images/drs14.2.webp', '/assets/images/drs14.3.webp', '/assets/images/drs14.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          402,
          '/assets/images/drs14.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs15.1.webp', '/assets/images/drs15.2.webp', '/assets/images/drs15.3.webp', '/assets/images/drs15.4.webp', '/assets/images/drs15.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          403,
          '/assets/images/drs15.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs16.1.webp', '/assets/images/drs16.2.webp', '/assets/images/drs16.3.webp', '/assets/images/drs16.4.webp', '/assets/images/drs16.5.webp', '/assets/images/drs16.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          404,
          '/assets/images/drs16.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs17.1.webp', '/assets/images/drs17.2.webp', '/assets/images/drs17.3.webp', '/assets/images/drs17.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          405,
          '/assets/images/drs17.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs18.1.webp', '/assets/images/drs18.2.webp', '/assets/images/drs18.3.webp', '/assets/images/drs18.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          250,
          '34 - 54',
          406,
          '/assets/images/drs18.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs19.1.webp', '/assets/images/drs19.2.webp', '/assets/images/drs19.3.webp', '/assets/images/drs19.4.webp', '/assets/images/drs19.5.webp', '/assets/images/drs19.6.webp', '/assets/images/drs19.7.webp', '/assets/images/drs19.8.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          407,
          '/assets/images/drs19.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs20.1.webp', '/assets/images/drs20.2.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          408,
          '/assets/images/drs20.2.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs21.1.webp', '/assets/images/drs21.2.webp', '/assets/images/drs21.3.webp', '/assets/images/drs21.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          409,
          '/assets/images/drs21.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images/drs22.1.webp', '/assets/images/drs22.2.webp', '/assets/images/drs22.3.webp', '/assets/images/drs22.4.webp', '/assets/images/drs22.5.webp', '/assets/images/drs22.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          410,
          '/assets/images/drs22.1.webp'
        ),
      
        

      ],
      'See more collection Allure 2023',
      '/assets/collages/collage_3.jpg'
    ),
     new collection(
      'collection Crystal 2023',
      [
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs1.1.webp', '/assets/images4/drs1.2.webp', '/assets/images4/drs1.3.webp', '/assets/images4/drs1.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          501,
          '/assets/images4/drs1.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs2.1.webp', '/assets/images4/drs2.2.webp', '/assets/images4/drs2.3.webp', '/assets/images4/drs2.4.webp', '/assets/images4/drs2.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          502,
          '/assets/images4/drs2.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs3.1.webp', '/assets/images4/drs3.2.webp', '/assets/images4/drs3.3.webp', '/assets/images4/drs3.4.webp', '/assets/images4/drs3.5.webp', '/assets/images4/drs3.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          503,
          '/assets/images4/drs3.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs4.1.webp', '/assets/images4/drs4.2.webp', '/assets/images4/drs4.3.webp', '/assets/images4/drs4.4.webp', '/assets/images4/drs4.5.webp', '/assets/images4/drs4.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          504,
          '/assets/images4/drs4.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs5.1.webp', '/assets/images4/drs5.2.webp', '/assets/images4/drs5.3.webp', '/assets/images4/drs5.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          505,
          '/assets/images4/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs6.1.webp', '/assets/images4/drs6.2.webp', '/assets/images4/drs6.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          506,
          '/assets/images4/drs6.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs7.1.webp', '/assets/images4/drs7.2.webp', '/assets/images4/drs7.3.webp', '/assets/images4/drs7.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          507,
          '/assets/images4/drs7.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs8.1.webp', '/assets/images4/drs8.2.webp', '/assets/images4/drs8.3.webp', '/assets/images4/drs8.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          508,
          '/assets/images4/drs8.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs9.1.webp', '/assets/images4/drs9.2.webp', '/assets/images4/drs9.3.webp', '/assets/images4/drs9.4.webp', '/assets/images4/drs9.5.webp', '/assets/images4/drs9.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          509,
          '/assets/images4/drs9.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs10.1.webp', '/assets/images4/drs10.2.webp', '/assets/images4/drs10.3.webp', '/assets/images4/drs10.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          510,
          '/assets/images4/drs10.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs11.1.webp', '/assets/images4/drs11.2.webp', '/assets/images4/drs11.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          511,
          '/assets/images4/drs11.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images4/drs12.1.webp', '/assets/images4/drs12.2.webp', '/assets/images4/drs12.3.webp', '/assets/images4/drs12.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          512,
          '/assets/images4/drs12.1.webp'
        ),
        
      
        

      ],
      'See more collection Crystal 2023',
      '/assets/collages/collage_5.jpg'










    ),
    new collection(
      'collection Muze 2023',
      [
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images5/drs1.1.webp', '/assets/images5/drs1.2.webp', '/assets/images5/drs1.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          601,
          '/assets/images5/drs1.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images5/drs2.1.webp', '/assets/images5/drs2.2.webp', '/assets/images5/drs2.3.webp', '/assets/images5/drs2.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          602,
          '/assets/images5/drs2.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images5/drs3.1.webp', '/assets/images5/drs3.2.webp', '/assets/images5/drs3.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          603,
          '/assets/images5/drs3.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images5/drs4.1.webp', '/assets/images5/drs4.2.webp', '/assets/images5/drs4.3.webp', '/assets/images5/drs4.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          604,
          '/assets/images4/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images5/drs5.1.webp', '/assets/images5/drs5.2.webp', '/assets/images5/drs5.3.webp', '/assets/images5/drs5.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          605,
          '/assets/images5/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images5/drs6.4.webp', '/assets/images5/drs6.2.webp', '/assets/images5/drs6.3.webp', '/assets/images5/drs6.1.webp', '/assets/images5/drs6.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          606,
          '/assets/images5/drs6.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images5/drs7.2.webp', '/assets/images5/drs7.1.webp', '/assets/images5/drs7.3.webp', '/assets/images5/drs7.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          607,
          '/assets/images5/drs7.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images5/drs8.3.webp', '/assets/images5/drs8.2.webp', '/assets/images5/drs8.1.webp', '/assets/images5/drs8.4.webp', '/assets/images5/drs8.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          0,
          '34 - 54',
          608,
          '/assets/images5/drs8.1.webp'
        ),
     
        
      
        

      ],
      'See more collection Muze 2023',
      '/assets/collages/collage_6.jpg'
    ),












    new collection(
      'collection White&Lace 2022',
      [
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs1.1.webp', '/assets/images3/drs1.2.webp', '/assets/images3/drs1.3.webp', '/assets/images3/drs1.4.webp', '/assets/images3/drs1.5.webp', '/assets/images3/drs1.6.webp', '/assets/images3/drs1.7.webp', '/assets/images3/drs1.8.webp', '/assets/images3/drs1.9.webp', '/assets/images3/drs1.10.webp', '/assets/images3/drs1.11.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          330,
          '34 - 54',
          500,
          '/assets/images3/drs1.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs2.1.webp', '/assets/images3/drs2.2.webp', '/assets/images3/drs2.3.webp', '/assets/images3/drs2.4.webp', '/assets/images3/drs2.5.webp', '/assets/images3/drs2.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          501,
          '/assets/images3/drs2.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs3.1.webp', '/assets/images3/drs3.2.webp', '/assets/images3/drs3.3.webp', '/assets/images3/drs3.4.webp', '/assets/images3/drs3.5.webp', '/assets/images3/drs3.6.webp', '/assets/images3/drs3.7.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          502,
          '/assets/images3/drs3.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs4.1.webp', '/assets/images3/drs4.2.webp', '/assets/images3/drs4.3.webp', '/assets/images3/drs4.4.webp', '/assets/images3/drs4.5.webp', '/assets/images3/drs4.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          503,
          '/assets/images3/drs4.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs5.1.webp', '/assets/images3/drs5.2.webp', '/assets/images3/drs5.3.webp', '/assets/images3/drs5.4.webp', '/assets/images3/drs5.5.webp', '/assets/images3/drs5.6.webp', '/assets/images3/drs5.7.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          504,
          '/assets/images3/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs6.1.webp', '/assets/images3/drs6.2.webp', '/assets/images3/drs6.3.webp', '/assets/images3/drs6.4.webp', '/assets/images3/drs6.5.webp', '/assets/images3/drs6.6.webp', '/assets/images3/drs6.7.webp', '/assets/images3/drs6.8.webp', '/assets/images3/drs6.9.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          505,
          '/assets/images3/drs6.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs7.1.webp', '/assets/images3/drs7.2.webp', '/assets/images3/drs7.3.webp', '/assets/images3/drs7.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          506,
          '/assets/images3/drs7.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs8.1.webp', '/assets/images3/drs8.2.webp', '/assets/images3/drs8.3.webp', '/assets/images3/drs8.4.webp', '/assets/images3/drs8.5.webp', '/assets/images3/drs8.6.webp', '/assets/images3/drs8.7.webp', '/assets/images3/drs8.8.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          330,
          '34 - 54',
          507,
          '/assets/images3/drs8.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs9.1.webp', '/assets/images3/drs9.2.webp', '/assets/images3/drs9.3.webp', '/assets/images3/drs9.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          508,
          '/assets/images3/drs9.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs10.1.webp', '/assets/images3/drs10.2.webp', '/assets/images3/drs10.3.webp', '/assets/images3/drs10.4.webp', '/assets/images3/drs10.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          240,
          '34 - 54',
          509,
          '/assets/images3/drs10.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs11.1.webp', '/assets/images3/drs11.2.webp', '/assets/images3/drs11.3.webp', '/assets/images3/drs11.4.webp', '/assets/images3/drs11.5.webp', '/assets/images3/drs11.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          510,
          '/assets/images3/drs11.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs12.1.webp', '/assets/images3/drs12.2.webp', '/assets/images3/drs12.3.webp', '/assets/images3/drs12.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          250,
          '34 - 54',
          511,
          '/assets/images3/drs12.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images3/drs13.1.webp', '/assets/images3/drs13.2.webp', '/assets/images3/drs13.3.webp', '/assets/images3/drs13.4.webp', '/assets/images3/drs13.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          240,
          '34 - 54',
          512,
          '/assets/images3/drs13.1.webp'
        ),



      ],
      'See more collection White&Lace 2022',
      '/assets/collages/collage_4.jpg'
    ),
/**
    new collection(
      'collection Amulet',
      [
        new Dress(
          'platya1',
          ['/assets/images1/drs1.1.jpg', '/assets/images1/drs1.2.jpg', '/assets/images1/drs1.3.jpg', '/assets/images1/drs1.4.jpg', '/assets/images1/drs1.5.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          250,
          '34 - 54',
          356,
          '/assets/images1/drs1.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs2.1.jpg', '/assets/images1/drs2.2.jpg', '/assets/images1/drs2.3.jpg', '/assets/images1/drs2.4.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          357,
          '/assets/images1/drs2.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs3.1.jpg', '/assets/images1/drs3.2.jpg', '/assets/images1/drs3.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          358,
          '/assets/images1/drs3.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs4.1.jpg', '/assets/images1/drs4.2.jpg', '/assets/images1/drs4.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          359,
          '/assets/images1/drs4.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs5.1.jpg', '/assets/images1/drs5.2.jpg', '/assets/images1/drs5.3.jpg', '/assets/images1/drs5.4.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          250,
          '34 - 54',
          360,
          '/assets/images1/drs5.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs6.1.jpg', '/assets/images1/drs6.2.jpg', '/assets/images1/drs6.3.jpg', '/assets/images1/drs6.4.jpg', '/assets/images1/drs6.5.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          361,
          '/assets/images1/drs6.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs7.1.jpg', '/assets/images1/drs7.2.jpg', '/assets/images1/drs7.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          250,
          '34 - 54',
          362,
          '/assets/images1/drs7.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs8.1.jpg', '/assets/images1/drs8.2.jpg', '/assets/images1/drs8.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          363,
          '/assets/images1/drs8.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs9.1.jpg', '/assets/images1/drs9.2.jpg', '/assets/images1/drs9.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          364,
          '/assets/images1/drs9.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs10.1.jpg', '/assets/images1/drs10.2.jpg', '/assets/images1/drs10.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          350,
          '34 - 54',
          365,
          '/assets/images1/drs10.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs11.1.jpg', '/assets/images1/drs11.2.jpg', '/assets/images1/drs11.3.jpg', '/assets/images1/drs11.4.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          366,
          '/assets/images1/drs11.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs12.1.jpg', '/assets/images1/drs12.2.jpg', '/assets/images1/drs12.3.jpg', '/assets/images1/drs12.4.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          367,
          '/assets/images1/drs12.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs13.1.jpg', '/assets/images1/drs13.2.jpg', '/assets/images1/drs13.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          225,
          '34 - 54',
          368,
          '/assets/images1/drs13.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs14.1.jpg', '/assets/images1/drs14.2.jpg', '/assets/images1/drs14.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          369,
          '/assets/images1/drs14.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs15.1.jpg', '/assets/images1/drs15.2.jpg', '/assets/images1/drs15.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          250,
          '34 - 54',
          370,
          '/assets/images1/drs15.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs16.1.jpg', '/assets/images1/drs16.2.jpg', '/assets/images1/drs16.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          371,
          '/assets/images1/drs16.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs17.1.jpg', '/assets/images1/drs17.2.jpg', '/assets/images1/drs17.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          450,
          '34 - 54',
          372,
          '/assets/images1/drs17.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs18.1.jpg', '/assets/images1/drs18.2.jpg', '/assets/images1/drs18.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          373,
          '/assets/images1/drs18.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs19.1.jpg', '/assets/images1/drs19.2.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          250,
          '34 - 54',
          374,
          '/assets/images1/drs19.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs20.1.jpg', '/assets/images1/drs20.2.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          225,
          '34 - 54',
          375,
          '/assets/images1/drs20.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs21.1.jpg', '/assets/images1/drs21.2.jpg', '/assets/images1/drs21.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          376,
          '/assets/images1/drs21.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs22.1.jpg', '/assets/images1/drs22.2.jpg', '/assets/images1/drs22.3.jpg', '/assets/images1/drs22.4.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          377,
          '/assets/images1/drs22.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs23.1.jpg', '/assets/images1/drs23.2.jpg', '/assets/images1/drs23.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          378,
          '/assets/images1/drs23.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs24.1.jpg', '/assets/images1/drs24.2.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          379,
          '/assets/images1/drs24.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs25.1.jpg', '/assets/images1/drs25.2.jpg', '/assets/images1/drs25.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          350,
          '34 - 54',
          380,
          '/assets/images1/drs25.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs26.1.jpg', '/assets/images1/drs26.2.jpg', '/assets/images1/drs26.3.jpg', '/assets/images1/drs26.4.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          290,
          '34 - 54',
          381,
          '/assets/images1/drs26.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs27.1.jpg', '/assets/images1/drs27.2.jpg', '/assets/images1/drs27.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          280,
          '34 - 54',
          382,
          '/assets/images1/drs27.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs28.1.jpg', '/assets/images1/drs28.2.jpg', '/assets/images1/drs28.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          383,
          '/assets/images1/drs28.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs29.1.jpg', '/assets/images1/drs29.2.jpg', '/assets/images1/drs29.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          310,
          '34 - 54',
          384,
          '/assets/images1/drs2.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs30.1.jpg', '/assets/images1/drs30.2.jpg', '/assets/images1/drs30.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          385,
          '/assets/images1/drs30.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs31.1.jpg', '/assets/images1/drs31.2.jpg', '/assets/images1/drs31.3.jpg', '/assets/images1/drs31.4.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          386,
          '/assets/images1/drs31.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs32.1.jpg', '/assets/images1/drs32.2.jpg', '/assets/images1/drs32.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          387,
          '/assets/images1/drs32.1.jpg'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images1/drs33.1.jpg', '/assets/images1/drs33.2.jpg', '/assets/images1/drs33.3.jpg'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          388,
          '/assets/images1/drs33.1.jpg'
        ),

      ],

      'See more collection Amulet',
      '/assets/collages/collage_1.jpg'
    ),
     */
    new collection(
      'collection Boheme',
      [
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs1.1.webp', '/assets/images2/drs1.2.webp', '/assets/images2/drs1.3.webp', '/assets/images2/drs1.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          300,
          '/assets/images2/drs1.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs2.1.webp', '/assets/images2/drs2.2.webp', '/assets/images2/drs2.3.webp', '/assets/images2/drs2.4.webp', '/assets/images2/drs2.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          400,
          '34 - 54',
          301,
          '/assets/images2/drs2.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs3.1.webp', '/assets/images2/drs3.2.webp', '/assets/images2/drs3.3.webp', '/assets/images2/drs3.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          302,
          '/assets/images2/drs3.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs4.1.webp', '/assets/images2/drs4.2.webp', '/assets/images2/drs4.3.webp', '/assets/images2/drs4.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          320,
          '34 - 54',
          303,
          '/assets/images2/drs4.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs5.1.webp', '/assets/images2/drs5.2.webp', '/assets/images2/drs5.3.webp', '/assets/images2/drs5.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          304,
          '/assets/images2/drs5.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs6.1.webp', '/assets/images2/drs6.2.webp', '/assets/images2/drs6.3.webp', '/assets/images2/drs6.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          240,
          '34 - 54',
          305,
          '/assets/images2/drs6.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs7.1.webp', '/assets/images2/drs7.2.webp', '/assets/images2/drs7.3.webp', '/assets/images2/drs7.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          220,
          '34 - 54',
          306,
          '/assets/images2/drs7.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs8.1.webp', '/assets/images2/drs8.2.webp', '/assets/images2/drs8.3.webp', '/assets/images2/drs8.4.webp', '/assets/images2/drs8.5.webp', '/assets/images2/drs8.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          307,
          '/assets/images2/drs8.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs9.1.webp', '/assets/images2/drs9.2.webp', '/assets/images2/drs9.3.webp', '/assets/images2/drs9.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          308,
          '/assets/images2/drs9.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs10.1.webp', '/assets/images2/drs10.2.webp', '/assets/images2/drs10.3.webp', '/assets/images2/drs10.4.webp', '/assets/images2/drs10.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          400,
          '34 - 54',
          309,
          '/assets/images2/drs10.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs11.3.webp', '/assets/images2/drs11.2.webp', '/assets/images2/drs11.1.webp', '/assets/images2/drs11.4.webp', '/assets/images2/drs11.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          240,
          '34 - 54',
          310,
          '/assets/images2/drs11.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs12.1.webp', '/assets/images2/drs12.2.webp', '/assets/images2/drs12.3.webp', '/assets/images2/drs12.4.webp', '/assets/images2/drs12.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          300,
          '34 - 54',
          311,
          '/assets/images2/drs12.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs13.1.webp', '/assets/images2/drs13.2.webp', '/assets/images2/drs13.3.webp', '/assets/images2/drs13.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          380,
          '34 - 54',
          312,
          '/assets/images2/drs13.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs14.1.webp', '/assets/images2/drs14.2.webp', '/assets/images2/drs14.3.webp', '/assets/images2/drs14.4.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          313,
          '/assets/images2/drs14.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs15.1.webp', '/assets/images2/drs15.2.webp', '/assets/images2/drs15.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          460,
          '34 - 54',
          314,
          '/assets/images2/drs15.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs16.1.webp', '/assets/images2/drs16.2.webp', '/assets/images2/drs16.3.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          260,
          '34 - 54',
          315,
          '/assets/images2/drs16.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs17.2.webp', '/assets/images2/drs17.3.webp', '/assets/images2/drs17.4.webp', '/assets/images2/drs17.5.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          270,
          '34 - 54',
          316,
          '/assets/images2/drs17.1.webp'
        ),
        new Dress( // KOPIRUVATU Z VITSU
          'platya32',// POHUI
          ['/assets/images2/drs18.1.webp', '/assets/images2/drs18.2.webp', '/assets/images2/drs18.3.webp', '/assets/images2/drs18.4.webp', '/assets/images2/drs18.5.webp', '/assets/images2/drs18.6.webp'],
          'Possibility to change color, length, made to measure, sewing with or without glitter. We arrange additional changes individually.',
          400,
          '34 - 54',
          317,
          '/assets/images2/drs18.1.webp'
        ),
      ],
      'See more collection Boheme',
      '/assets/collages/collage_2.jpg'

    ),
    // new collection(
    //   'Popularne suknie',
    //   [
    //     new Dress(
    //       'platya12',
    //       [
    //         'https://www.pexels.com/photo/man-in-white-crew-neck-t-shirt-and-blue-denim-jeans-sitting-on-gray-concrete-bench-1998261/',
    //         'https://www.pexels.com/photo/pink-and-white-flowers-in-tilt-shift-lens-4630002/',
    //       ],
    //       'descr',
    //       100,
    //       '34 - 54',
    //       300,
    //       'ass'
    //     ),
    //   ],
    //   'See morekolekcję 2020/2021',
    //   'https://images.pexels.com/photos/2472858/pexels-photo-2472858.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    // ),
  ];

  getcollection() {
    return this.collections.slice();
  }

  getSinglecollection() {
    this.singlecollection = this.collections.find(
      (item) => item.name == this.collectionName
    );
  }

  getSingleDress() {
    this.singleDress = this.singlecollection.dresses.find(
      (item) => item.index == this.dressIndex);

  }
}
