import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appShowImg]'
})
export class ShowImgDirective {

  constructor() { }

  @HostBinding('class.image-visible')isOpen = false;

  @HostListener('mouseenter') togleOpen(){

    this.isOpen = true;

  }

 @HostListener('mouseleave') togleClose(){

    this.isOpen = false;

  }


}
