import { Injectable } from '@angular/core';
import { Card } from './card/card.model';


@Injectable({
  providedIn: 'root'
})
export class CardService{

  constructor() { }


  cards = [new Card("#","567", 50, "https://images.pexels.com/photos/2122363/pexels-photo-2122363.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"),
  new Card("#","56", 50, "https://images.pexels.com/photos/157860/wedding-dresses-fashion-character-bride-157860.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"),
  new Card("#","56", 50, "https://images.pexels.com/photos/2122358/pexels-photo-2122358.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"),
  new Card("#","56", 50, "https://images.pexels.com/photos/3399691/pexels-photo-3399691.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500")]

  getCards(){
    return this.cards.slice();
  }

}
