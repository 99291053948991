import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onActivate(event) {
    window.scroll(0, 0);
  }

  onActivateCarousel(event) {
    window.scroll(2300, 1800);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
