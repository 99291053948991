<div class="col row col-12">
  <main class="page-content center-text1">
    <div class="card card-horizontal">
      <div class="content copy">
        <p>
          «Upodobała ci się collection sukień? Przejdź do katalogu i See more
          modeli sukień ślubnych.»
        </p>
      </div>
    </div>

    <div class="container col-10">
      <p>
        Nasza firma z ponad 30 letnim doświadczeniem w projektowaniu i szyciu
        sukień ślubnych zajmuje się sprzedażą na Ukrainie. Już od 4 lat
        współpracujemy z salonami na Polskim rynku ślubnym. Popularne salony
        ufają nam, i nadal prowadzą z nami współpracę.
      </p>
      <button class="btn" (click)="onActivateCarousel($event)">See more</button>
    </div>
  </main>
</div>

<div class="col row col-12">
  <main class="page-content center-text2">
    <div class="container col-10">
      <p class="center-text">
        My śledzimy za światowymi trendami w modzie ślubnej, i staramy się
        zadowalać naszych Panien Młodych najpiękniejszymi sukniami. Sprzedajemy
        suknie ślubne wyłącznie w hurtowych cenach. Naszym celem jest zaczęcie
        współpracy z Wami, nasze umowy na pewno Wam się spodobają!
      </p>
      <button class="btn" (click)="onActivateCarousel($event)">See more</button>
    </div>

    <div class="card">
      <div class="content copy">
        <p>
          «Kochać to nie znaczy patrzeć na siebie nawzajem, lecz patrzeć razem w
          tym samym kierunku.»
        </p>
      </div>
    </div>
  </main>
</div>
