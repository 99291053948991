<header class="header" (onscroll)="doSomething()">
  <nav
    #triger
    class="navbar navbar-default fixed-top py-3 d-flex justify-content-end"
  >
    <img
      class="nav-logo rounded-pill justify-content-center pos-rel"
      src="assets/logo4.png"
      alt=""
    />
    <app-burger class="collection-button"></app-burger>
    <a routerLink="/" class="navbar-brand">Roxolana Bridal</a>
  </nav>
</header>
