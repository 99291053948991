<div
  class="row col d-flex container-fluid section-container col-12 justify-content-center"
>
  <div class="carousel-container mt-5 mb-5">
    <div
      id="carouselExampleFade"
      class="carousel slide carousel-fade"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="assets/collage3.webp" class="d-block w-100" alt="..." />
          <div class="carousel-caption d-none d-md-block copy">
            <p>
              Jesteście zainteresowani współpracą z nami? Z niecierpliwością
              czekamy na kontakt n Nami, mamy dużo pięknych sukień, które na
              pewno Wam się spodobają.
            </p>
            <button routerLink="/collection/collection White&Lace 2022" class="btn">See more</button>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/collage.webp" class="d-block w-100" alt="..." />
          <div class="carousel-caption d-none d-md-block copy">
            <p>
              Jesteście zainteresowani współpracą z nami? Z niecierpliwością
              czekamy na kontakt n Nami, mamy dużo pięknych sukień, które na
              pewno Wam się spodobają.
            </p>
            <button routerLink="/collection/collection Allure 2023" class="btn">See more</button>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/collage1.webp" class="d-block w-100" alt="..." />
          <div class="carousel-caption d-none d-md-block copy">
            <p>
              Jesteście zainteresowani współpracą z nami? Z niecierpliwością
              czekamy na kontakt n Nami, mamy dużo pięknych sukień, które na
              pewno Wam się spodobają.
            </p>
            <button routerLink="/collection/collection Allure 2023" class="btn">See more</button>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/collage2.webp" class="d-block w-100" alt="..." />
          <div class="carousel-caption d-none d-md-block copy">
            <p>
              Jesteście zainteresowani współpracą z nami? Z niecierpliwością
              czekamy na kontakt n Nami, mamy dużo pięknych sukień, które na
              pewno Wam się spodobają.
            </p>
            <button routerLink="/collection/collection Boheme" class="btn">See more</button>
          </div>
        </div>
      </div>
      
      <a
        class="carousel-control-prev"
        href="#carouselExampleFade"
        role="button"
        data-slide="prev"
      >
        <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        left
        <span class="sr-only">Previous</span> -->
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleFade"
        role="button"
        data-slide="next"
      >
        <!-- right<span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span> -->
      </a>
    </div>
  </div>
</div>
