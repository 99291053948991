import { Component, OnInit, Input } from '@angular/core';
import { collection } from '../shared/collection.model';
import { collectionService } from '../shared/collection-service.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-collection-page',
  templateUrl: './collection-page.component.html',
  styleUrls: ['./collection-page.component.css']
})
export class collectionPageComponent implements OnInit {

  constructor(private dresscollection: collectionService, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.route.params.subscribe( (params: Params) => {this.dresscollection.collectionName = params['name']
    this.dresscollection.getSinglecollection();
    this.collectionDisplayed = this.dresscollection.singlecollection;;} );
  }

  collectionDisplayed: collection;

}
