import { Component, OnInit,} from '@angular/core';
import { collectionService } from 'src/app/shared/collection-service.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Dress } from 'src/app/shared/dress.model';
import { ViewportScroller } from '@angular/common';
import { collection } from 'src/app/shared/collection.model';

@Component({
  selector: 'app-dress-page',
  templateUrl: './dress-page.component.html',
  styleUrls: ['./dress-page.component.css'],
})
export class DressPageComponent implements OnInit {
  constructor(
    private dresscollection: collectionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.dresscollection.dressIndex = params['dress'];
      this.dresscollection.collectionName = params['name'];
      this.dresscollection.getSinglecollection();
      this.dresscollection.getSingleDress();
      this.dressDisplayed = this.dresscollection.singleDress;
      this.collectionDisplayed = this.dresscollection.singlecollection;

      console.log(this.dressDisplayed)
    });
  }

  dressDisplayed: Dress;
  collectionDisplayed: collection;
}
