<div
  class="hero"
  [ngStyle]="{
    'background-image': 'url(' + dressDisplayed.titlePhoto + ')'
  }"
>
  <div class="gradient container-fluid d-flex justify-content-center">
    <div class="content">
      <div class="content-text">
        <p class="hero-text-top">{{ collectionDisplayed.name }}</p>
        <div class="flex-row">
          <hr />
          <div class="row gapLink heroButtons justify-content-center">
            <a class="btn">Qualiry style bridal</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dress-info col row col-12 mt-4 mb-5 d-flex justify-content-center">
  <nz-carousel class="carousel ml-4" [nzEffect]="'fade'" nzAutoPlay>
    <div [style.backgroundImage]="'url('+ dress +')'" class="carousel-content" nz-carousel-content
      *ngFor="let dress of dressDisplayed.imgPath">
      <div class="content">
      </div>
    </div>
  </nz-carousel>

  <div class="copy container col row ml-3 list-reviews border">
    <ul class="list-unstyled col-6">
      <h5>INDEX</h5>
      <p>
        {{ dressDisplayed.index }}
      </p>
      <hr />
    </ul>
    <ul class="list-unstyled col-6">
      <h5>SIZE</h5>
      <p>
        {{ dressDisplayed.size }}
      </p>
      <hr />
    </ul>

    <ul class="list-unstyled position-center col-12">
      <h5>DRESS DESCRIPTION</h5>
      <p>
        {{ dressDisplayed.description }}
      </p>
      <hr />
    </ul>
  </div>
</div>
