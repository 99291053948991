<div
  class="hero"
  [ngStyle]="{
    'background-image': 'url(' + collectionDisplayed.collectionPhoto + ')'
  }"
>
  <div class="gradient container-fluid d-flex justify-content-center">
    <div class="content ">
      <div class="content-text ">
        <p class="hero-text-top">{{ collectionDisplayed.name }}</p>
        <div class="flex-row">
          <hr />
          <div class="row gapLink heroButtons justify-content-center">
            <a class="btn">QUALITY STYLE BRIDAL</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="container-fluid">
  <div class="card-container row d-flex flex-row justify-content-center">
    <app-card
      *ngFor="let dress of collectionDisplayed.dresses"
      [dressToDisplay]="dress"
      [dresscollectionName]="collectionDisplayed.name"
    ></app-card>
  </div>
</section>
