import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener, HostBinding  } from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
  }

    @ViewChild('triger') body: ElementRef;
  isOpen = false;


  @HostListener('window:scroll', ['$event'])
  doSomething() {
    if(window.pageYOffset > 10)
    this.renderer.addClass(this.body.nativeElement,"active");
    else
    this.renderer.removeClass(this.body.nativeElement,"active");

  }

}
