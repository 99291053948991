<main class="page-content">
  <div
    class="card"
    [ngStyle]="{ 'background-image': 'url(' + dressToDisplay.imgPath[0] + ')' }"
  >
    <div class="content copy d-flex justify-content-center">
      <ul class="row col">
        <li class="col-12">
          <h5>collection</h5>
          <p>
            {{ collectionDisplayed.name }}
          </p>
          <h5> </h5>
        </li>
      </ul>

      <ul class="row col d-flex justify-content-around">
        <li class="col-4">
          <h5>SIZE</h5>
          <p>
            {{ dressToDisplay.size }}
          </p>
        </li>
        <li class="col-4">
          <h5>INDEX</h5>
          <p>
            {{ dressToDisplay.index }}
          </p>
        </li>
      </ul>

      <button routerLink="/collection/{{dresscollectionName}}/{{dressToDisplay.index}}" class="btn">See more</button>
    </div>
  </div>
</main>
