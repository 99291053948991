import { Directive, HostListener, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appDroptrigger]'
})
export class DroptriggerDirective {

  constructor() { }


  @HostBinding('class.nav-open')isOpen = false;

  @HostListener('click') togleOpen(){

    this.isOpen = !this.isOpen;

  }


}
