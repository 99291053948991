<div class="nav-body" appDroptrigger>
  <div class="nav-trigger d-flex justify-content-start collection">collections</div>
  <nav class="nav-container" id="navigation">
    <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="collection-list">
      <a class="btn" *ngFor="let collection of collections" (mouseenter)="setcollection(collection)" class="btn"
        [routerLink]="'/collection/' + collection.name">{{ collection.name }}</a>
    </div>
  </nav>

  <div class="overlay copy d-flex justify-content-center" [style.backgroundImage]="'url('+ imageTo +')'">
    <div class="gradient">
      <div class="text-container">
        <span class="text"> {{ description}}</span>
        <button *ngIf="name" [routerLink]="'/collection/' + name" class="btn btn-overlay">See more</button>
      </div>
    </div>
  </div>
</div>
<!-- improve main element 3d movement -->
