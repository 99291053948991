import { Component, OnInit } from '@angular/core';
import { collectionService } from '../shared/collection-service.service';
import { collection } from '../shared/collection.model';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(private dresscollection: collectionService) { }

  isSpinner = true;

  backgroundImage = '/assets/collages/collage_8_blur.webp';

  collages = [{
    link:'/collection/collection Bloomed Bliss 2024',
    image: '/assets/collages/collage_8.webp',
    bluredImage: '/assets/collages/collage_8_blur.webp',
    collection: 'collection Bloomed Bliss 2024'
    
  },
  {
    link:'/collection/collection Lace Harmony 2024',
    image: '/assets/collages/collage_9.webp',
    bluredImage: '/assets/collages/collage_9_blur.webp',
    collection: 'collection Lace Harmony 2024'
    
  },
  {
    link:'/collection/collection Esmee 2023',
    image: '/assets/collages/collage_7.webp',
    bluredImage: '/assets/collages/collage_7_blur.webp',
    collection: 'collection Esmee 2023'
    
  },
    {
    link:'/collection/collection Allure 2023',
    image: '/assets/collages/collage_3.webp',
    bluredImage: '/assets/collages/collage_3_blur.webp',
    collection: 'collection Allure 2023'
    
  },
  {
    link:'/collection/collection Crystal 2023',
    image: '/assets/collages/collage_5.webp',
    bluredImage: '/assets/collages/collage_5_blur.webp',
    collection: 'collection Crystal 2023'
    
  },
  {
    link:'/collection/collection Muze 2023',
    image: '/assets/collages/collage_6.webp',
    bluredImage: '/assets/collages/collage_6_blur.webp',
    collection: 'collection Muze 2023'
    
  },
  ];

  collectionDisplayed: collection;

  ngOnInit(): void {
    this.dresscollection.collectionName = 'collection Bloomed Bliss 2024'
    this.refresh();
  }

  refresh() {
    this.isSpinner = true;
    this.dresscollection.getSinglecollection();

    setTimeout(() => {
      this.collectionDisplayed = this.dresscollection.singlecollection;

      this.isSpinner = false;
    }, 1000);
  }

  getCarouselIndex(index) {
    this.backgroundImage = this.collages[index].bluredImage;
  }

  tabChange(tabInfo) {
    this.dresscollection.collectionName = tabInfo.tab.nzTitle;
    this.refresh();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
}


}
