<div class="section-color-main">
  <section class="hero container-fluid" #target [style.backgroundImage]="'url('+ backgroundImage +')'">
    <nz-carousel class="carousel" [nzEffect]="'fade'" nzAutoPlay (nzAfterChange)="getCarouselIndex($event)">
      <div [style.backgroundImage]="'url('+ collage.image +')'" class="carousel-content" nz-carousel-content
        *ngFor="let collage of collages">
        <div class="content">
          <button routerLink="{{collage.link}}" class="content-btn">See more: {{collage.collection}}</button>
        </div>
      </div>
    </nz-carousel>
  </section>

  <section class="tab-section">
    <nz-tabset [nzType]="'card'" class="tabs" (nzSelectChange)="tabChange($event)">
      <nz-tab class="tab" nzTitle="{{collage.collection}}" *ngFor="let collage of collages">
        <nz-spin nzSimple [nzSize]="'large'" *ngIf="isSpinner"></nz-spin>
        <ng-container *ngIf="!isSpinner">
          <div class="tab-info">
            <span class="info-text">
              Liked {{collage.collection}} press button to See more
            </span>
            <button routerLink="{{collage.link}}" class="info-btn">See more: {{collage.collection}}</button>
          </div>

          <div class="card-container">
            <app-card *ngFor="let dress of collectionDisplayed.dresses | slice: 0:8" [dressToDisplay]="dress" [dresscollectionName]="collage.collection">
            </app-card>
          </div>
        </ng-container>
      </nz-tab>
    </nz-tabset>
  </section>

  <div class="medium-section">
    <section class="container">
      <div class="d-flex justify-content-center">
        <div class="copyP d-flex row col mt-5 border-bottom border-dark">
          <ul class="list-unstyled">
            <li class="media">
              <img class="mr-3 rounded-pill border border-white" src="assets/weddingdress2.webp" alt="Review" />
              <div class="media-body">
                <h5 class="mt-0 mb-1">Best quality materials</h5>
                <p>
                  We use the best materials for sewing dresses. Quality and modern trends are our main criteria by which we are guided. Tailoring from the smallest to the largest sizes.
                </p>
              </div>
            </li>
          </ul>
          <ul class="list-unstyled">
            <li class="media">
              <div class="media-body">
                <h5 class="mr-3 d-flex justify-content-end">Sewing your own dress projects</h5>
                <p class="d-flex justify-content-end">
                  
We make exclusive dress models a reality. Do you want to complement the concept of your salon with new dresses? We are happy to provide you dozens of dress models for you.
                </p>
              </div>
              <img class="mr-3 rounded-pill border border-white" src="assets/weddingdress6.webp" alt="Review" />
            </li>
          </ul>
          <ul class="list-unstyled">
            <li class="media">
              <img class="mr-3 rounded-pill border border-white" src="assets/weddingdress7.webp" alt="Review" />
              <div class="media-body">
                <h5 class="mt-0 mb-1">The best conditions for cooperation</h5>
                <p>
                 
We do everything to make our cooperation flawless. Fast tailoring times, minimum order of 1 dress, more than 100 ready-to-tailor dress models and frequent collection updates
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>

  <section class="about-us">
    <div class="about-info">
      <div class="text-info">
        <h5 class="about-header">About us</h5>
        <p class="info-text"> Our company has many years of experience in the field of wedding industry and fashion. Thousands of satisfied brides have passed through us during this period of time. We cooperate with wedding salons, produce dresses at wholesale prices. Contact us, we will give you more information about us!</p>
      </div>
      <div class="about-image"></div>
    </div>
  </section>
</div>
