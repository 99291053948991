import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-vertical',
  templateUrl: './card-vertical.component.html',
  styleUrls: ['./card-vertical.component.css']
})
export class CardVerticalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
}

onActivateCarousel(event) {
  window.scroll(1900, 1900);
}

}
