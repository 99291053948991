import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {Routes, RouterModule} from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BurgerComponent } from './header/burger/burger.component';
import { CardVerticalComponent } from './shared/card-vertical/card-vertical.component';
import { CarouselComponent } from './shared/carousel/carousel.component';
import { DroptriggerDirective } from './header/burger/droptrigger.directive';
import { collectionService } from "./shared/collection-service.service";
import { ShowImgDirective } from './header/burger/show-img.directive';
import { collectionPageComponent } from './collection-page/collection-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { CardComponent } from './collection-page/card/card.component';
import { CardService} from './collection-page/card.service';
import { FooterComponent } from './footer/footer.component';
import { DressPageComponent } from './collection-page/dress-page/dress-page.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSpinModule } from 'ng-zorro-antd/spin';

const appRoutes: Routes = [
  {path: '', component: HomePageComponent},
  {path: 'collection/:name', component: collectionPageComponent},
  {path: 'collection/:name/:dress', component: DressPageComponent},
  {path: '**', redirectTo: '/'}

]

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BurgerComponent,
    CardVerticalComponent,
    DroptriggerDirective,
    ShowImgDirective,
    collectionPageComponent,
    HomePageComponent,
    CarouselComponent,
    CardComponent,
    FooterComponent,
    DressPageComponent,
  ],
  imports: [
    BrowserModule,
    NzCarouselModule,
    NzTabsModule,
    NzSpinModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [collectionService, CardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
