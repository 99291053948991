<!-- Footer -->
<footer class="page-footer font-small stylish-color-dark pt-4">
  <h6 class="text-uppercase mb-4 font-weight-bold"> Contact us</h6>

  <div class="d-flex justify-content-center effect thurio" #target>
    <div class="buttons">
      <a href="https://api.whatsapp.com/send?phone=380636498042" class="vimeo"
        title="Skontaktuj się z nami w Whatsapp"><i class="fa fa-whatsapp" [ngStyle]="{ color: '#25D366' }"></i>
      </a>
      <a href="https://www.facebook.com/Roxolana-Bridal-106565668770441" class="insta" title="Skontaktuj się z nami w Facebook"><i
          class="fa fa-facebook" [ngStyle]="{ color: '#4267B2' }"></i></a>
      <a href="https://www.instagram.com/roxolana.bridal/" class="insta" title="Skontaktuj się z nami w Instagram"><i
          class="fa fa-instagram" [ngStyle]="{ color: '#C13584' }"></i></a>
    </div>
  </div>
  <!-- Social buttons -->
  <hr />
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3 copy">
    © 2018 - 2022 | made By Easy Company | All rights reserved
  </div>
</footer>
<!-- Footer -->
