import { Component, OnInit, Input } from '@angular/core';
import { Card } from './card.model';
import {CardService} from '../card.service'
import { Dress } from 'src/app/shared/dress.model';
import { collection } from 'src/app/shared/collection.model';
import { collectionService } from 'src/app/shared/collection-service.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  cards: Card[];

  @Input() dressToDisplay: Dress;
  @Input() dresscollectionName: string;

  ngOnInit(): void {
    this.cards = this.cardsService.getCards();
    this.collectionDisplayed = this.dresscollection.singlecollection;
  }

  collectionDisplayed: collection;

  constructor(private cardsService: CardService,
    private dresscollection: collectionService,){

  }

}
