import { Component, OnInit} from '@angular/core';
import { collection } from "../../shared/collection.model"
import { collectionService } from 'src/app/shared/collection-service.service';

@Component({
  selector: 'app-burger',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.css']
})
export class BurgerComponent implements OnInit {

  backImg = "https://images.pexels.com/photos/3693894/pexels-photo-3693894.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
  collections: collection[];
  collectionToDisplay: collection;
  description: string;
  imageTo: string;
  name: string;
  isVisible = false;

  ngOnInit(): void {
    this.collections = this.dresscollection.getcollection();
    this.setcollection(this.collections[0]);
  }


  constructor(private dresscollection: collectionService){

  }

  setcollection(coll: collection){
    this.imageTo = coll.collectionPhoto;
    this.description = coll.description;
    this.name = coll.name;

    this.collectionToDisplay = coll;
    this.dresscollection.singlecollection = coll;

  }

}
